<template>
  <div class="page-gallery__item">
    <div
      class="page-gallery__item--photo"
      style="cursor: pointer"
      :style="{
        ...(aspectRatio ? { 'aspect-ratio': aspectRatio, height: '' } : {}),
      }"
      @click="$emit('zoom')"
    >
      <img :src="url" />
    </div>
    <div class="page-gallery__item--profile" v-if="likeable || total">
      <div class="page-gallery__item--profile__controls">
        <div class="page-gallery__item--profile__control">
          <v-tooltip bottom color="primary" v-if="likeable">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red"
                @click="onDoubleClick"
                text
                fab
                small
                v-bind="attrs"
                v-on="on"
                style="padding: 0; margin: 0"
              >
                <v-icon style="padding: 0; margin: 0">{{
                  isLikedByUser ? "mdi-heart" : "mdi-heart-outline"
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{ isLikedByUser ? "Убрать лайк" : "Поставить лайк!" }}</span>
          </v-tooltip>
          <span v-if="total">{{ total }}</span>
        </div>
      </div>
      <h5 v-if="author">{{ author }}</h5>
    </div>
  </div>
</template>

<script>
import likeableMixin from "@/mixins/likeable";

export default {
  name: "GalleryItem",
  props: [
    "url",
    "id",
    "author",
    "likeable",
    "total",
    "leader",
    "bonus",
    "aspect-ratio",
  ],
  mixins: [likeableMixin],
  computed: {
    likeableObjectId() {
      return this.id;
    },
  },
  methods: {
    onDoubleClick() {
      if (this.likeable) {
        this.isLikedByUser = !this.isLikedByUser;
      }
    },
    onZoomClick() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style scoped>
.gallery-title {
  word-break: normal;
}
.gallery-bonus {
  font-weight: 400;
}
</style>

<style scoped>
.page-gallery {
  box-sizing: border-box;
  padding: 30px 45px;
  overflow-y: scroll;
  background: rgba(255, 252, 247, 0.7);
  height: 100%;
}
.page-gallery h3 {
  font-family: var(--secondary-font);
  font-size: 20px;
  color: var(--quests-header-color); /* ПЕРЕНАЗВАТЬ */
  text-align: center;
  margin-bottom: 10px;
}
.page-gallery p {
  font-family: var(--main-font);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-text);
  margin-bottom: 20px;
  text-align: center;
}
.page-gallery__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: max-content;
  gap: 20px;
}
.page-gallery::-webkit-scrollbar {
  width: 5px;
}
.page-gallery::-webkit-scrollbar-track {
  background-color: transparent;
}
.page-gallery::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.page-gallery__item {
  border-radius: 10px;
  background: #fbf4e4; /* ЗАМЕНИТЬ НА ПЕРЕМЕННУЮ */
}
.page-gallery__item--photo {
  position: relative;
  border-radius: 10px;
}
.page-gallery__item--photo::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.page-gallery__item--photo img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10px;
}
.page-gallery__item--profile {
  box-sizing: border-box;
  padding: 15px 10px;
}
.page-gallery__item--profile__controls {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.page-gallery__item--profile__control {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.page-gallery__item--profile__control img {
  width: 24px;
  height: 24px;
}
.page-gallery__item--profile__control span {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.page-gallery__item--profile h5 {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}
/* Стили блока с комментами */
@keyframes popup-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#popup {
  position: fixed;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: rgba(48, 17, 8, 0.2);
  z-index: 900;
  display: none;
  animation-name: popup-show;
  animation-duration: 0.2s;
}
.page-gallery__comments {
  display: flex;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.page-gallery__comments--prev,
.page-gallery__comments--next {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.page-gallery__comments--prev img,
.page-gallery__comments--next img {
  width: 40px;
  height: 40px;
}
.page-gallery__comments--wrap {
  height: 100%;
  background: #fffcf7;
  position: relative;
  border-radius: 10px;
  display: flex;
}
.page-gallery__comments--wrap--photo {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.page-gallery__comments--wrap--photo img {
  width: 100%;
  height: auto;
}
.page-gallery__comments--content {
  box-sizing: border-box;
  height: 100%;
  width: 390px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
}
.page-gallery__comments--comms-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 20px;
}
.page-gallery__comments--comms-wrapper::-webkit-scrollbar {
  width: 5px;
}
.page-gallery__comments--comms-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}
.page-gallery__comments--comms-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
  border-radius: 10px;
}
.page-gallery__comment--input {
  box-sizing: border-box;
  width: 100%;
  height: 85px;
  padding: 20px 0;
  border-top: 1px solid var(--scrollbar-color);
  display: inline-flex;
  gap: 10px;
  background: #fffcf7;
  position: relative;
}
.page-gallery__comment--input input[type="text"] {
  box-sizing: border-box;
  padding: 10px 40px 10px 15px;
  width: 100%;
  border: 1px solid #e6dbd4; /* СОЗДАТЬ ПЕРЕМЕННУЮ*/
  border-radius: 20px;
  background: #fff;
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-secondary);
  outline: none;
}
.page-gallery__comment--input-send {
  position: absolute;
  right: 15px;
  top: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.page-gallery__comment--input-send img {
  width: 20px;
  height: 20px;
}
.page-gallery__comments--header {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--scrollbar-color);
}
.page-gallery__comments--header--profile {
  display: flex;
  gap: 10px;
  align-items: center;
}
.page-gallery__comments--photo {
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
.page-gallery__comments--photo img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
.page-gallery__comments--name {
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 600;
}
.page-gallery__comments--text {
  font-family: var(--main-font);
  font-size: 14px;
}
.page-gallery__comments--text h4 {
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.page-gallery__comments--controls {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}
.page-gallery__comments--control {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.page-gallery__comments--control img {
  width: 24px;
  height: 24px;
}
.page-gallery__comments--control span {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text);
}
.page-gallery__comments--comms {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  gap: 20px;
}
.no-comments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
}
.no-comments h6 {
  font-family: var(--main-font);
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text);
}
.no-comments span {
  font-family: var(--main-font);
  font-size: 14px;
  color: var(--color-text-secondary);
}
.page-gallery__comment {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.page-gallery__comments--close-btn {
  position: absolute;
  top: 0px;
  right: -29px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 1001;
}
.page-gallery__comments--close-btn img {
  width: 24px;
  height: 24px;
}
.page-gallery__comments--header-mobile {
  display: none;
}
.page-gallery__item .page-gallery__comments--header--profile {
  display: none;
}
.page-gallery__item .page-gallery__comments--text {
  display: none;
}
@media (max-width: 860px) {
  .page-gallery__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 390px) {
  .page-gallery {
    background: transparent;
    padding: 0;
  }
  .page-gallery h3 {
    text-align: left;
  }
  .page-gallery p {
    text-align: left;
  }
  .page-gallery__items {
    grid-template-columns: 1fr;
    border-radius: 0;
  }
  .page-gallery__comments {
    bottom: 0;
    z-index: 1000;
    flex-direction: column;
    gap: 0;
    justify-content: end;
    padding: 0;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .page-gallery__comments--prev,
  .page-gallery__comments--next {
    display: none;
  }
  .page-gallery__comments--wrap {
    height: 80%;
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .page-gallery__comments--wrap--photo {
    display: none;
  }
  .page-gallery__comments--header {
    display: none;
  }
  .page-gallery__comments--content {
    padding: 30px 15px;
    width: 100%;
  }
  .page-gallery__comments--close-btn {
    display: none;
  }
  .page-gallery__comment--input {
    height: 65px;
    padding: 15px 0 0 0;
    border-top: 1px solid var(--scrollbar-color);
    display: inline-flex;
    gap: 10px;
    background: #fffcf7;
    position: relative;
  }
  .page-gallery__comment--input input[type="text"] {
    box-sizing: border-box;
    padding: 10px 40px 10px 15px;
    width: 100%;
    border: 1px solid #e6dbd4; /* СОЗДАТЬ ПЕРЕМЕННУЮ*/
    border-radius: 20px;
    background: #fff;
    font-family: var(--main-font);
    font-size: 14px;
    color: var(--color-text-secondary);
    outline: none;
  }
  .page-gallery__comment--input-send {
    position: absolute;
    right: 15px;
    top: 30px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .page-gallery__comment--input-send img {
    width: 20px;
    height: 20px;
  }
  .page-gallery__comments--comms-wrapper {
    justify-content: flex-start;
  }
  .page-gallery__comments--header-mobile {
    display: block;
  }
  .page-gallery__comments--header-mobile h5 {
    font-family: var(--main-font);
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .page-gallery__items {
    gap: 30px;
  }
  .page-gallery__item .page-gallery__comments--header--profile {
    display: flex;
    margin-bottom: 10px;
  }
  .page-gallery__item .page-gallery__comments--text {
    display: block;
  }
  .page-gallery__item--profile h5 {
    display: none;
  }
  .page-gallery__item--profile {
    padding: 10px 0;
  }
  .page-gallery__comments--text h4 {
    margin-bottom: 5px;
  }
  .page-gallery__comments--text p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 5px;
  }
  .page-gallery__comments--text span {
    font-family: var(--main-font);
    font-size: 14px;
    color: var(--color-text-secondary);
    cursor: pointer;
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selectedChallenge == null)?_c('welcome',{attrs:{"itemId":_vm.itemId,"params":_vm.params}}):_vm._e(),(_vm.selectedChallenge)?_c('div',[(
        _vm.status === 'ready' || _vm.status === 'expired' || _vm.status === 'cancelled'
      )?_c('pending',{attrs:{"challenge":_vm.selectedChallenge}}):(
        _vm.status === 'pending' ||
        _vm.status === 'accepted' ||
        _vm.status === 'resolved' ||
        _vm.status === 'cancelled'
      )?[(_vm.gameType === 'air-battle')?_c('air-battle',{attrs:{"challenge":_vm.selectedChallenge,"is-my":_vm.isMyChallenge,"user-id":_vm.userId,"from-user-id":_vm.fromUserId,"to-user-id":_vm.toUserId,"remaining":_vm.selectedChallengeRemaining,"is-tournament":_vm.isTournament},on:{"command":function($event){return _vm.onChallengeCommand($event)},"close":_vm.onClose}}):_vm._e()]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
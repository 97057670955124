<template>
  <div>
    <welcome
      v-if="selectedChallenge == null"
      :itemId="itemId"
      :params="params"
    />
    <div v-if="selectedChallenge">
      <pending
        :challenge="selectedChallenge"
        v-if="
          status === 'ready' || status === 'expired' || status === 'cancelled'
        "
      />
      <template
        v-else-if="
          status === 'pending' ||
          status === 'accepted' ||
          status === 'resolved' ||
          status === 'cancelled'
        "
      >
        <air-battle
          :challenge="selectedChallenge"
          :is-my="isMyChallenge"
          :user-id="userId"
          :from-user-id="fromUserId"
          :to-user-id="toUserId"
          :remaining="selectedChallengeRemaining"
          @command="onChallengeCommand($event)"
          @close="onClose"
          v-if="gameType === 'air-battle'"
          :is-tournament="isTournament"
        />
      </template>
    </div>
  </div>
</template>

<script>
import AirBattle from "../../../games/air-battle/Index.vue";
import Welcome from "./Welcome.vue";
import Pending from "./Pending.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  name: "SnowFight2025",
  props: ["params", "type", "itemId"],
  components: {
    AirBattle,
    Welcome,
    Pending,
  },
  data() {
    return {
      getInitials,
      checkTimer: null,
      activeCheckTimer: null,
    };
  },
  created() {
    if (this.checkTimer) {
      clearInterval(this.checkTimer);
      this.checkTimer = null;
    }
    this.checkTimer = setInterval(() => {
      this.fetchActualChallenge();
    }, 2500);
    this.checkActiveChallenge();
  },
  beforeDestroy() {
    clearInterval(this.checkTimer);
    this.checkTimer = false;
  },
  computed: {
    ...mapGetters({
      activeChallenge: "event/challenge/activeChallenge",
      selectedChallenge: "event/challenge/selectedChallenge",
      selectedChallengeRemaining: "event/challenge/selectedChallengeRemaining",
      user: "event/user",
    }),
    isFinished() {
      return ["cancelled", "expired", "resolved"].includes(
        this.selectedChallenge.status
      );
    },
    isTournament() {
      return this.params.mode === "tournament";
    },
    gameType() {
      return _.get(this.selectedChallenge, "gameId");
    },
    status() {
      return _.get(this.selectedChallenge, "status");
    },
    userId() {
      return _.get(this.user, "id");
    },
    fromUserId() {
      return _.get(this.selectedChallenge, "from.id");
    },
    toUserId() {
      return _.get(this.selectedChallenge, "to.id");
    },
    isMyChallenge() {
      return this.fromUserId === this.userId;
    },
  },
  methods: {
    ...mapActions({
      cancelChallenge: "event/challenge/cancelChallenge",
      checkActiveChallenge: "event/challenge/checkActiveChallenge",
      fetchChallenge: "event/challenge/fetchChallenge",
      acceptChallenge: "event/challenge/acceptChallenge",
      activateChallenge: "event/challenge/activateChallenge",
      runChallengeCommand: "event/challenge/runChallengeCommand",
      postChallengeChatMessage: "event/challenge/postChallengeChatMessage",
    }),
    ...mapMutations({
      setActiveChallenge: "event/challenge/setActiveChallenge",
      setSelectedChallenge: "event/challenge/setSelectedChallenge",
    }),
    onClose() {
      console.log("close in root component");
      this.setActiveChallenge(null);
      this.setSelectedChallenge(null);
    },
    fetchActualChallenge() {
      if (this.selectedChallenge != null) {
        // this.checkActiveChallenge();
        this.fetchChallenge(this.selectedChallenge._id);
      } else {
        this.checkActiveChallenge();
      }
    },
    onCancelChallenge() {
      this.cancelChallenge(this.selectedChallenge._id);
    },
    onAcceptChallenge() {
      this.acceptChallenge(this.selectedChallenge._id);
    },
    onActivateChallenge() {
      this.activateChallenge(this.selectedChallenge._id);
    },
    async onChallengeCommand({ command, params }) {
      if (command === "close") {
        return this.onClose();
      }
      await this.runChallengeCommand({
        id: this.selectedChallenge._id,
        command,
        params,
      });
      this.fetchActualChallenge();
    },
    async onChatMessageChange() {
      const message = this.chatMessage.trim();
      if (!message) return;
      this.chatMessage = "";
      this.postChallengeChatMessage({
        id: this.selectedChallenge._id,
        message,
      });
    },
  },
  watch: {
    // selectedChallenge(val) {
    //   console.log("selectedChallenge", val);
    // },
  },
};
</script>